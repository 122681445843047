.main {
    margin: 0;
    padding: 0;
}

.homescreen {
    background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, .85)),
    url('../../images/main-bg.jpg');
    background-position: center top;
    background-size: cover;
}

.homescreenContainer {
    margin: 0 auto;
    padding: 0 32px 30px;
    max-width: var(--width-content);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
}

.homescreenProducts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    gap: 30px
}

.homescreenTitle {
    margin: 0;
    padding: 80px 0 80px;
    max-width: 600px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 43px;
    color: var(--color-text-secondary);
    opacity: .95;
}

.about {
    margin: 0;
    padding: 80px 0 100px;
    box-sizing: border-box;
}

.aboutContainer {
    padding: 0 135px 0 32px;
    max-width: var(--width-content);
    display: flex;
    flex-direction: row;
    min-height: 300px;
    justify-content: space-between;
    margin: 0 auto;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.aboutWrapper {
    margin: 0;
    padding: 0 100px 0 0;
    max-width: 650px;
    align-self: flex-start;
}

.subtitle {
    margin: 0;
    padding: 0 0 30px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: var(--color-text-primary);
}

.aboutImage {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    min-width: 392px;
}

.caption {
    margin: 0;
    padding: 0 0 30px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
}

.info {
    margin: 0;
    padding: 0 0 100px;
    background-color: var(--color-bg-secondary);
}

.infoContainer {
    padding: 60px 32px;
    max-width: var(--width-content);
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    box-sizing: border-box;
    gap: 50px;
    flex-wrap: wrap-reverse;
    align-items: flex-end;
}

.infoWrapper {
    margin: 0;
    padding: 0;
    max-width: 600px;
}

.infoImage {
    max-height: 497px;
}

.boldText {
    font-weight: 700;
}

@media screen and (max-width: 1279px) {
    .infoImage, .aboutImage {
        min-width: 100%;
    }

    .aboutImage {
        padding-top: 30px;
    }

    .homescreenProducts {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        gap: 20px;
    }

    .aboutWrapper {
        padding: 0;
        max-width: 100%;
    }

    .aboutContainer {
        padding: 0 20px;
    }

    .subtitle {
        font-size: 28px;
        line-height: 32px;
    }

    .infoContainer {
        padding: 50px 20px 0;
    }

    .homescreenContainer {
        padding: 0 20px 20px
    }

    .homescreenTitle {
        padding: 35px 0;
        font-size: 30px;
        line-height: 36px;
    }

    .homescreen {
        background-size: 200%;
        background-repeat: no-repeat;
        background-image: url('../../images/main-bg.jpg');
        background-position: top center;
    }

    .about {
        padding: 45px 0 50px;
    }

    .info {
        padding-bottom: 50px;
    }
}