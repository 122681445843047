.info {
    padding: 25px 0 110px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--width-content);
    min-height: calc(var(--vh, 1vh) * 100 - 271px);
}

.title {
    margin: 0;
    padding: 45px 32px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: var(--color-text-primary);
}

.text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
    padding: 0 32px 25px;
    max-width: 650px;
}

.infoGrid {
    max-width: 1230px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    gap: 30px;
    padding: 0 32px;
    list-style: none;
    margin: 0;
}

.infoItem {
    max-height: 100px;
    background-color: var(--color-bg-secondary);
    padding: 0;
    margin: 0;
    position: relative;
}

.infoItemTitle {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    text-transform: uppercase;
    color: var(--color-text-primary);
    z-index: 1;
    position: relative;
    margin: 0;
    padding: 0;
}

.image {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.link {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 38px 30px;
    box-sizing: border-box;
    align-items: center;
}

.link::after {
    content: url(../../images/main-arrow.svg);
    width: 30px;
    height: 30px;
    object-fit: contain;
    transition: all .3s ease-in-out;
    z-index: 1;
}

.link:hover::after {
    transform: translateX(5px);
}

@media screen and (max-width: 1279px) {
    .infoGrid {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        padding: 0 20px;
    }

    .infoItemTitle {
        font-size: 18px;
        line-height: 22px;
    }

    .title {
        font-size: 28px;
        line-height: 32px;
        padding: 45px 20px;
    }

    .text {
        padding: 0 20px 25px;
    }

    .infoItem {
        max-height: max-content;
    }
}