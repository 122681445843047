.overlay {
    background-color: var(--overlay-bg);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(var(--vh, 1vh) * 100);
}

.container {
    background-color: var(--color-bg-body);
    z-index: 1;
    padding: 50px 100px 65px;
    box-sizing: border-box;
    position: relative;
}

.title {
    margin: 0;
    padding: 0 0 30px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: var(--color-text-primary);
    user-select: none;
}

.close {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    width: 24px;
    height: 24px;
    background-image: url(../../images/component-modal-close.svg);
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    background-size: contain;
    background-repeat: no-repeat;
}

.close:hover {
    opacity: .5;
}

.close:active {
    transform: scale(.95);
}

@media screen and (max-width: 1279px) {
    .container {
        padding: 20px 20px 40px;
        max-width: calc(100% - 40px);
    }

    .title {
        font-size: 28px;
        line-height: 32px;
    }
}