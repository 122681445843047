@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(./fonts/Roboto-Light.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/Roboto-Regular.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/Roboto-Bold.woff) format('woff');
}

@font-face {
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/Verdana-Regular.woff) format('woff');
}

@font-face {
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/Verdana-Bold.woff) format('woff');
}

:root {
    --color-bg-primary: #53a156;
    --color-bg-secondary: #e6e7e2;
    --color-bg-body: #fff;
    --color-text-primary: #1c1d1b;
    --color-text-secondary: #f6f5f4;
    --width-content: 1600px;
    --mobile-width-content: 100%;
    --overlay-bg: rgba(28,29,27, .6);
    --overlay-offcanvas: rgba(246,245,244, 1);
    --color-tap-highlight: rgba(0, 255, 7, 0.29);
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-bg-body);
    min-width: 320px;
    overflow-x: scroll;
    -webkit-tap-highlight-color: var(--color-tap-highlight);
}

/* breadcrumbs active class */

.active {
    font-weight: 400;
}

p {
    margin: 0;
    padding: 0;
}

@media (-webkit-device-pixel-ratio: 1.25) {
    :root {
        zoom: 0.8;
    }
}

@media (-webkit-device-pixel-ratio: 1.5) {
    :root {
        zoom: 0.8;
    }
}

