.agreement {
    padding: 25px 0 110px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--width-content);
}

.title {
    margin: 0;
    padding: 45px 32px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: var(--color-text-primary);
}

.agreementContainer {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
    max-width: 700px;
    margin: 0;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.subtitle {
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    padding-top: 30px;
}

.list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    counter-reset: bold-numbers;
    box-sizing: border-box;
}

.listItem {
    counter-increment: bold-numbers;
}

.listItem::before {
    content: counter(bold-numbers) ". ";
    color: var(--color-text-primary);
    font-weight: 700;
}

.bold {
    font-weight: 700;
}

.table {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.row {
    display: flex;
    flex-direction: row;
    border-top: 1px solid var(--color-bg-secondary);
    justify-content: space-between;
    min-height: 50px;
    align-items: center;
}

@media screen and (max-width: 1279px){
    .title {
        font-size: 28px;
        line-height: 32px;
    }
}