.payment {
    padding: 25px 0 110px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--width-content);
    min-height: calc(var(--vh, 1vh) * 100 - 271px);
}

.paymentContainer {
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    gap: 125px;
}

.title {
    margin: 0;
    padding: 45px 32px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: var(--color-text-primary);
}

.info{
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 600px;
}

.caption {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-text-primary);
    margin: 0;
    padding: 0;
}

.map {
    width: 100%;
    height: 330px;
}

.tabs {
    display: flex;
    flex-direction: row;
    list-style: none;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: var(--color-text-primary);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.name {
    padding: 16px 38px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
}

.requisites {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    width: 600px;
}

.tabsContainer {
    padding-top: 25px;
}

.active {
    font-weight: 400;
    border: 1px solid var(--color-text-primary);
}

.regular {
    font-weight: 400;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 25px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
}

@media screen and (max-width: 1279px) {
    .paymentContainer{
        gap: 20px;
        padding: 0 20px;
        flex-wrap: wrap;
    }
    .tabs {
        flex-wrap: wrap;
    }

    .requisites {
        width: 100%;
    }

    .title {
        font-size: 28px;
        line-height: 32px;
        padding: 45px 20px;
    }

}