.navigation {
    background-color: var(--color-bg-secondary);
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    max-width: var(--width-content);
    box-sizing: border-box;
    min-height: 61px;
    margin: 0 auto;
    list-style: none;
}

.desktopNav {
    display: flex;
    flex-direction: row;
    width: calc(100% - 300px);
    justify-content: space-between;
    padding-right: 50px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
}

.button {
    font-family: Verdana, sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text-primary);
    text-transform: uppercase;
    margin: 0;
    padding: 12px 18px;
    background: transparent;
    border: 1px solid var(--color-text-primary);
    box-sizing: border-box;
    cursor: pointer;
    white-space: nowrap;
}

.button:hover {
    opacity: .5;
}

.button:active {
    transform: scale(.9);
}

.button:disabled {
    opacity: .5;
    transform: scale(.9);
}

.time {
    font-size: 14px;
    line-height: 16px;
    display: none;
}



@media screen and (max-width: 1279px) {
    .time {
        display: inline-block;
    }

    .desktopNav {
        display: none;
    }

    .container {
        padding: 0 22px;
    }
}