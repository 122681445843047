.certificate {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 185px;
}

.image {
    border: 1px solid var(--color-text-secondary);
}

.name {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media screen and (max-width: 1279px) {
    .certificate {
        max-width: 80%;
    }
}
