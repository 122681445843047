.more {
    text-decoration: none;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-text-primary);
}

.more::after {
    content: url(../../images/component-more.svg);
    width: 30px;
    margin-left: 20px;
    object-fit: contain;
    display: inline-block;
    vertical-align: middle;
    transition: all .3s ease-in-out;
}

.more:hover::after {
    transform: translateX(5px);
}