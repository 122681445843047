.product {
    background-color: var(--color-bg-secondary);
    margin: 0;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
    min-height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;
    overflow: hidden;
}

.productProductsPage {
    background-color: var(--color-bg-secondary);
    margin: 0;
    padding: 30px 25px 40px;
    box-sizing: border-box;
    position: relative;
    min-height: 410px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;
}

.image {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
}

.imageProductsPage {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.title {
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    text-transform: uppercase;
    margin: 0;
    padding: 0 0 10px;
    position: relative;
    z-index: 1;
}

.caption {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
}

.link {
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-text-primary);
    align-self: flex-end;
    position: relative;
    z-index: 2;
}

.link::after {
    content: url(../../images/main-arrow.svg);
    width: 30px;
    height: 30px;
    margin-left: 15px;
    object-fit: contain;
    display: inline-block;
    vertical-align: middle;
    transition: all .3s ease-in-out;
}

.link:hover::after {
    transform: translateX(5px);
}

.specs {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.button {
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    line-height: 23px;
    padding: 10px;
    color: var(--color-text-secondary);
    background-color: var(--color-bg-primary);
    position: relative;
    z-index: 2;
    transition: opacity .2s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
}

.button:hover {
    opacity: .8;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

@media screen and (max-width: 1279px) {
    .product {
        min-height: 170px;
        padding: 20px 30px 25px;
    }

    .image {
        left: 0;
        bottom: -60px;
    }

    .productProductsPage {
        min-height: 230px;
        padding: 20px 30px 40px;
    }

    .wrapper {
        gap: 20px;
        padding: 10px 0 0;
    }
}