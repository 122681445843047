.product {
    padding: 25px 0 0;
    box-sizing: border-box;
    margin: 0 auto;
}

.productContainer {
    max-width: var(--width-content);
    margin: 0 auto;
    padding: 0;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
}

.infoTable {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
}

.infoTableRow {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.infoTableRowContent {
    flex-basis: 140px;
}

.summary {
    max-width: 390px;
}

.title {
    margin: 0;
    padding: 45px 32px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: var(--color-text-primary);
    box-sizing: border-box;
}

.container {
    margin: 0;
    padding: 0 32px 55px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}

.form {
    min-width: 495px;
    border: 2px solid var(--color-text-secondary);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tabs {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 85px;
    min-height: 65px;
    background-color: var(--color-text-secondary);
    align-items: center;
    overflow-x: scroll;
}

.tabsOverlay {
    width: 100%;
    background-color: var(--color-text-secondary);
}

.tabsContainer {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 32px;
    max-width: var(--width-content);
}

.name {
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 65px;
    text-transform: uppercase;
    padding: 0 35px;
    box-sizing: border-box;
    user-select: none;
    white-space: nowrap;
}

.active {
    font-weight: 400;
    background-color: var(--color-bg-secondary);
    border-top: 5px solid var(--color-bg-primary);
}

.content {
    box-sizing: border-box;
    background-color: var(--color-bg-secondary);
    padding: 30px 32px 100px;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 600px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
}


.bold {
    font-weight: 700;
}

.link {
    text-decoration: none;
    color: var(--color-text-primary);
    border-bottom: 1px solid var(--color-text-primary);
    transition: opacity .2s ease-in-out;
}

.link:hover {
    opacity: .5;
}

.certified {
    display: flex;
    align-items: center;
    align-self: flex-start;
}

.certified::before {
    content: url(../../images/product-certified.svg);
    width: 30px;
    height: 30px;
    margin-right: 20px;
    object-fit: contain;
}

.certificationContainer {
    display: flex;
    flex-direction: row;
    gap: 25px;
    max-width: max-content;
}

.certificateWrapper {
    display: flex;
    gap: 20px;
}

.preview {
    max-width: 185px;
}

.image {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1279px) {
    .tabs {
        gap: 20px;
    }

    .title {
        font-size: 28px;
        line-height: 32px;
        padding: 45px 20px;
    }

    .container {
        padding: 0 20px 55px;
    }

    .tabsContainer {
        padding: 0 20px;
    }

    .content {
        padding: 30px 20px 100px;
    }

    .image {
        aspect-ratio: 1 / 1;
        width: 100%;
        object-fit: cover;
        object-position: top;
    }

    .certificationContainer {
        flex-wrap: wrap;
    }

    .certificateWrapper {
       flex-direction: column;
       align-items: center;
       width: 100%;
    }
}
