.top {
    background-color: var(--color-bg-primary);
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 22px 32px;
    max-width: var(--width-content);
    box-sizing: border-box;
    min-height: 70px;
    margin: 0 auto;
}

.logo {
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-text-secondary);
    text-decoration: none;
    text-transform: uppercase;
}

.info {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    gap: 50px;
    color: var(--color-text-secondary);
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.time {
    font-size: 14px;
    line-height: 16px;
}

.phone {
    font-size: 22px;
    line-height: 26px;
}

.controls {
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    align-items: center;
}

.toggle {
    display: none;
}

.hamburger {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    width: 24px;
    height: 12px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../../images/component-top-hamburger.svg);
    display: none;
}

.close {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../../images/component-top-close.svg);
}

.phoneButton {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    width: 24px;
    height: 24px;
    background-image: url(../../images/component-top-phone.svg);
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
}

.phoneButtonContainer {
    display: none;
}

.navigationMobile {
    display: none;
}

.containerMobile {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 50px;
    padding: 0 20px;
}

@media screen and (max-width: 1279px) {
    .time {
        display: none;
    }

    .phone {
        display: none;
    }

    .hamburger {
        display: inline-block;
    }

    .phoneButtonContainer {
        display: inline-block;
    }

    .navigationMobile {
        display: inline-block;
    }

    .container {
        padding: 22px 20px;
    }
}