.about {
    padding: 25px 0 110px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--width-content);
    min-height: calc(var(--vh, 1vh) * 100 - 271px);
}

.aboutContainer {
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    gap: 125px;
    flex-wrap: wrap;
}

.title {
    margin: 0;
    padding: 45px 32px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: var(--color-text-primary);
}

.info {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 600px;
}

.caption {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
    margin: 0;
    padding: 0;
}

.image {
    width: 630px;
    height: 330px;
    object-fit: cover;
    object-position: center center;
}

@media screen and (max-width: 1279px) {
    .image {
        width: 100%;
        height: auto;
    }

    .title {
        font-size: 28px;
        line-height: 32px;
        padding: 45px 20px;
    }

    .aboutContainer {
        padding: 0 20px;
    }
}