.form {
    margin: 0 0 -25px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.fieldset {
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.field {
    display: flex;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    flex-direction: column;
    align-content: flex-start;
}

.input {
    min-width: 392px;
    min-height: 45px;
    box-sizing: border-box;
    margin: 0;
    padding: 10px 20px;
    border: 1px solid var(--color-text-secondary);
    outline: none;
    border-radius: 0;
    background-color: var(--color-text-secondary);
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
}

.input:focus {
    border: 1px solid var(--color-bg-primary);
    box-sizing: border-box;
}

.textarea {
    min-width: 392px;
    min-height: 115px;
    box-sizing: border-box;
    margin: 0;
    padding: 10px 20px;
    border: 1px solid transparent;
    outline: none;
    border-radius: 0;
    resize: none;
    background-color: var(--color-text-secondary);
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
}

.textarea:focus {
    border: 1px solid var(--color-bg-primary);
    box-sizing: border-box;
}

.controls {
    display: flex;
    flex-direction: column;
}

::placeholder {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
    opacity: .3;
}

.error {
    min-width: 392px;
    min-height: 45px;
    box-sizing: border-box;
    margin: 0;
    padding: 10px 20px;
    border: 1px solid red;
    outline: none;
    border-radius: 0;
    background-color: var(--color-text-secondary);
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
}

.errorMessage {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: red;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.errorContainer {
    min-height: 25px;
}

.reset {
    margin: 0;
    padding: 10px 25px;
    background-color: transparent;
    outline: none;
    border: 1px solid var(--color-bg-primary);
    color: var(--color-bg-primary);
    cursor: pointer;
    min-height: 45px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    box-sizing: border-box;
    text-align: center;
    width: 50%;
    transition: all .3s ease-in-out;
}

.reset:disabled {
    border-color: var(--color-bg-secondary);
    color: var(--color-bg-secondary);
}

.submit {
    margin: 0;
    padding: 10px 25px;
    background-color: var(--color-bg-primary);
    color: var(--color-text-secondary);
    outline: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    min-height: 45px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    width: 50%;
    transition: all .3s ease-in-out;
    white-space: nowrap;
    -webkit-tap-highlight-color: var(--color-tap-highlight);
}

.submit:disabled {
    background-color: var(--color-bg-secondary);
    color: var(--color-bg-body);
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 25px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.state {
    min-height: 40px;
    margin: 0 0 -40px;
    padding: 5px 0;
    box-sizing: border-box;
}

.stateErrorMessage {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: red;
    max-width: 392px;
}

.stateSuccessMessage {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-bg-primary);
    max-width: 392px;
}

.sendError {
    margin: 0;
    padding: 10px 25px;
    outline: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    min-height: 45px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    width: 50%;
    transition: all .3s ease-in-out;
    background-color: red;
    color: var(--color-text-secondary);
}

@media screen and (max-width: 1279px) {
    .input, .textarea, .error {
        width: 100%;
        min-width: auto;
    }
    .form {
        margin: 0;
        flex-wrap: wrap;
        gap: 0;
    }

    .buttonsContainer {
        flex-wrap: wrap;
        width: 100%;
    }

    .controls {
        width: 100%;
    }

    .submit, .reset {
        width: 100%;
    }
}