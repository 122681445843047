.products {
    padding: 25px 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--width-content);
    min-height: calc(var(--vh, 1vh) * 100 - 271px);
}

.title {
    margin: 0;
    padding: 45px 32px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: var(--color-text-primary);
}

.productsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 290px));
    gap: 30px;
    padding: 0 32px;
}

.info {
    margin: 0;
    padding-left: 20px;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.caption {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
}

.bold {
    font-weight: 700;
}

@media screen and (max-width: 1279px) {
    .productsGrid {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        padding: 0 20px;
    }

    .title {
        font-size: 28px;
        line-height: 32px;
        padding: 45px 20px;
    }
}

