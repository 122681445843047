.link {
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text-primary);
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 17px;
    border-bottom: 0 solid var(--color-bg-primary);
    white-space: nowrap;
}



.link:hover {
    border-bottom: 5px solid var(--color-bg-primary);
}

.active {
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text-primary);
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 17px;
    border-bottom: 5px solid var(--color-bg-primary);
}

.link:hover {
    opacity: .5;
}