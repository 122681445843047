.notFound {
    padding: 25px 0 110px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--width-content);
    min-height: calc(var(--vh, 1vh) * 100 - 271px);
}

.notFoundContainer {
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    gap: 125px;
}

.title {
    margin: 0;
    padding: 45px 32px 10px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 57px;
    line-height: 69px;
    color: var(--color-text-primary);
    opacity: .1;
}

.subtitle {
    margin: 0;
    padding: 0 32px 25px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    color: var(--color-text-primary);
    text-transform: uppercase;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 600px;
}

.caption {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
    margin: 0;
    padding: 0;
}

.image {
    width: 630px;
    height: 330px;
    object-fit: cover;
    object-position: center center;
}

.button {
    font-family: Verdana, sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text-primary);
    text-transform: uppercase;
    margin: 0;
    padding: 12px 18px;
    background: transparent;
    border: 1px solid var(--color-text-primary);
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    max-width: 250px;
    text-align: center;
}

.button:hover {
    opacity: .5;
}

.button:active {
    transform: scale(.9);
}

@media screen and (max-width: 1279px) {
    .subtitle {
        font-size: 28px;
        line-height: 32px;
    }

    .notFoundContainer{
        gap: 20px;
        padding: 0 20px;
        flex-wrap: wrap;
    }

    .image {
        width: 100%;
    }
}