.contacts {
    padding: 25px 0 110px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--width-content);
    min-height: calc(var(--vh, 1vh) * 100 - 271px);
}

.contactsContainer {
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    gap: 125px;
}

.title {
    margin: 0;
    padding: 45px 32px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: var(--color-text-primary);
}

.map {
    width: 100%;
    height: 330px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 35px;
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
    max-width: 600px;
    min-height: 390px;
}

.name {
    font-weight: 400;
    display: block;
    padding-bottom: 5px;
    margin: 0;
}

.link {
    text-decoration: none;
    color: var(--color-text-primary);
    border-bottom: 1px solid var(--color-text-primary);
    transition: opacity .2s ease-in-out;
}

.link:hover {
    opacity: .5;
}

.address {
    width: 600px;
}

@media screen and (max-width: 1279px){
    .address {
        width: 100%;
    }
    
    .contactsContainer{
        gap: 20px;
        padding: 0 20px;
        flex-wrap: wrap;
    }

    .list {
        min-height: auto;
    }

    .title {
        font-size: 28px;
        line-height: 32px;
        padding: 45px 20px;
    }
}