.certificates {
    padding: 25px 0 110px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--width-content);
    min-height: calc(var(--vh, 1vh) * 100 - 271px);
}

.title {
    margin: 0;
    padding: 45px 32px;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: var(--color-text-primary);
}

.text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-text-primary);
    padding: 0 32px 25px;
    max-width: 650px;
}

.certificatesContainer {
    margin: 0;
    padding: 0 32px;
    display: flex;
    gap: 30px;
}

@media screen and (max-width: 1279px){
    .title {
        font-size: 28px;
        line-height: 32px;
        padding: 45px 20px;
    }

    .text {
        padding: 0 20px 25px;
    }

    .certificatesContainer {
        padding: 0 20px 25px;
        flex-direction: column;
        align-items: center;
    }
}
