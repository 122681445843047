.breadcrumbs {
    display: flex;
    flex-direction: row;
    opacity: .5;
    font-weight: 300;
    padding: 0 32px;
    max-width: var(--width-content);
    margin: 0 auto;
    box-sizing: border-box;
}

.link {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    color: var(--color-text-primary);
}

.link::after {
    content: url(../../images/component-breadcrumbs-arrow.svg);
    width: 12px;
    margin: 0 20px;
    object-fit: contain;
    transition: all .3s ease-in-out;
}

.link:last-child::after {
    content: '';
    width: 0;
}

@media screen and (max-width: 1279px) {
    .link {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
    }

    .breadcrumbs {
        padding: 0 20px;
        row-gap: 10px;
        flex-wrap: wrap;
    }
}