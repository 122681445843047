.footer {
    min-height: 140px;
    background-color: var(--color-bg-primary);
    margin: 0;
    padding: 40px 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footerContainer {
    max-width: var(--width-content);
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    gap: 120px;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
    flex-wrap: wrap;
}

.footerItem {
    list-style: none;
    color: var(--color-text-secondary);
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.footerInfo {
    font-size: 16px;
    line-height: 23px;
}

.footerPhone {
    font-size: 22px;
    line-height: 26px;
}

.footerLink {
    text-decoration: none;
    color: var(--color-text-secondary);
    transition: opacity .2s ease-in-out;
}

.footerLink:hover {
    opacity: .5;
}

@media screen and (max-width: 1279px) {
    .footerContainer {
        gap: 30px;
        padding: 0;
    }

    .footer {
        padding: 40px 20px;
    }
}